<template>
  <div class="bodyBox">
    <div class="title">
      提交订单
    </div>
    <div class="flex_box">
      <div style="width: 4rem;height:4rem"><img :src="detailsData.cover_pic" alt="" style="width: 100%;height:100%">
      </div>
      <div class="nameFlex">
        <div style="width: 100%;">
          <div class="textOne">{{ detailsData.name }}</div>
          <div class="color_red">￥{{ detailsData.price }}</div>
        </div>
      </div>
    </div>
    <div>
      <van-form>
        <van-cell-group inset>
          <van-field v-model="phone" type="tel" style="zoom:none !important" name="电话号码" label="电话号码" placeholder="电话号码"
            maxlength="11" :rules="[{ required: true, message: '请填写电话号码' }]" />
          <van-field v-model="username" name="填写姓名" style="zoom:none !important" label="填写姓名" placeholder="填写姓名"
            :rules="[{ required: true, message: '请填写您的姓名' }]" />
          <van-field v-model="result" is-link style="zoom:none !important" readonly name="picker" label="所属城市"
            placeholder="点击选择城市" @click="showPicker = true" />
          <van-field v-model="addrss_detail" name="详细地址" style="zoom:none !important" label="详细地址" placeholder="详细地址"
            :rules="[{ required: true, message: '请填写详细地址' }]" />

          <div style="font-size: 0.3rem; color: red;margin: var(--van-cell-group-inset-padding);">
            注意:提交订单信息支付完成后，自动提货，系统将在1周内完成发货，填写地址错误可能导致发货不准确，请在本周内注意查看手机短信，支付完成后会发送短信通知您所中奖商品
          </div>
        </van-cell-group>
      </van-form>
    </div>
    <van-popup v-model:show="showPicker" position="bottom">
      <van-picker :columns="columns" :columns-field-names="{ text: 'label' }" @confirm="onConfirm"
        @cancel="showPicker = false" />
    </van-popup>
    <div class="button">
      <div class="btn" @click="getPayment">确认支付</div>
    </div>
    <div style="padding: 0 .24rem;" class="radio">
      <van-radio-group v-model="checked">
        <van-radio checked-color="#e74f3f" name="1">提交即视为您已阅读并同意<span style="color: #e74f3f;"
            @click="$router.push('/userAgreement')">《用户协议》</span>和<span style="color: #e74f3f;"
            @click="$router.push('/privacyPage')">《隐私协议》</span></van-radio>
      </van-radio-group>
    </div>
    <van-overlay :show="showLoad" @click="showLoad = false">
      <div style="display:flex;justify-content:center;flex-direction:column;height:100%;text-align:center" @click.stop>
        <van-loading size="50px" type="spinner" color="#1989fa"></van-loading>
        <div style="color:#1989fa;margin-top:20px">支付加载中</div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import axios from 'axios'
import { showToast } from 'vant'
export default {
  data() {
    return {
      showLoad: false,
      price_view: '',
      box_id: '',
      detailsData: {},
      username: '',
      result: '',
      addrss_detail: '',
      showPicker: false,
      goods_id: "",
      channel_id: "",
      pay_price: "0",
      phone: "",
      pay_type: "",
      pay_mode: "",
      adv_m_id: "",
      adv_client_id: "",
      adv_client_appid: "",
      channel_no: '',
      coupon_id: "",
      payIcon: "",
      buttonChange: false,
      platfrom: "",
      openid: '',
      subTrue: true,
      order_url_c: '',
      checked: '1'
    }
  },
  created() {
    this.goods_id = this.getQueryString("goods_id");
    this.payIcon = this.getQueryString("payIcon");
    this.channel_id = this.getQueryString("channel_id");
    this.pay_price = this.getQueryString("pay_price");
    this.phone = ''
    this.pay_type = this.getQueryString("pay_type");
    this.pay_mode = this.getQueryString("pay_mode");
    this.adv_m_id =
      this.getQueryString("adv_m_id") == "undefined" ||
        !this.getQueryString("adv_m_id")
        ? ""
        : this.getQueryString("adv_m_id");
    if (this.getQueryString("adv_m_id") == "undefined" ||
      !this.getQueryString("adv_m_id")) {

    } else {
      sessionStorage.setItem('adv_m_id', this.getQueryString("adv_m_id"))
    }

    this.adv_client_id = this.getQueryString("adv_client_id");
    this.adv_client_appid = this.getQueryString("adv_client_appid");
    this.callback_url = this.getQueryString("callback_url");
    this.channel_no = this.getQueryString("channel_no");
    this.coupon_id = this.getQueryString("coupon_id");
    this.platfrom = this.getQueryString("platfrom");
    this.payVideo = this.getQueryString("payVideo")
    this.box_id = this.getQueryString("goods_id");
    this.getBoxList()
    this.getCityList()

  },
  mounted() {

    //   window.addEventListener('pageshow', function (e) {
    //     if(e.persisted || (window.performance && window.performance.navigation.type == 2)){
    //             window.location.reload()
    //     }
    // });
    document.addEventListener("visibilitychange", () => {
      // console.log(504)
      if (
        this.buttonChange &&
        (this.getQueryString("return_url") || this.order_url_c) &&
        !document.hidden
      ) {

        this.buttonChange = false;
        if (this.getQueryString("return_url")) {
          window.location.href = unescape(this.getQueryString("return_url"));
        }
        if (this.isBaiDu()) {
          // this.iframe_rul=''
          window.location.href = unescape(this.order_url_c)
        } else {
          window.location.href = unescape(this.getQueryString("return_url"));
        }


      }
    });

  },
  methods: {
    isBaiDu() {
      var sUserAgent = navigator.userAgent.toLowerCase();
      if (sUserAgent.indexOf('baidu') > 0) {
        return true
      }
      return false
    },
    updateQueryStringParameter(uri, key, value) {
      if (!value) {
        return uri;
      }
      uri = uri.split('#/details').join('')
      var re = new RegExp('(^|&)' + key + '=([^&]*)(&|$)', 'i');
      var separator = uri.indexOf('?') !== -1 ? "&" : "?";
      if (uri.indexOf(key) !== -1) {
        return uri + '#/details'
      }
      if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2') + '#/details';
      }
      else {
        return uri + separator + key + "=" + value + '#/details';
      }
    },
    getCityList() {
      axios.get("/api/v1/default/districtList").then((res) => {
        console.log(res.data.data);
        this.columns = res.data.data;
      });
    },
    onConfirm(e) {
      this.result = "";
      this.resetObj();
      e.selectedOptions.forEach((element, index) => {
        if (index == e.selectedOptions.length - 1) {
          this.result += element.label;
        } else {
          this.result += element.label + "-";
        }
      });
      this.tihuoObj = {
        province: e.selectedOptions[0].label,
        province_id: e.selectedOptions[0].value,
        city: e.selectedOptions[1].label,
        city_id: e.selectedOptions[1].value,
        district: e.selectedOptions[2].label,
        district_id: e.selectedOptions[2].value,
      };
      this.showPicker = false;
      console.log(e);
    },
    getQueryString(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      }
      return '';
    },
    phoneOs() {
      var u = navigator.userAgent,

        isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1,

        isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

      if (isAndroid) {
        return 'Android'
      }

      if (isiOS) {
        return 'IOS'
      }
      return ''
    },
    resetObj() {
      this.tihuoObj = {
        province: "",
        province_id: "",
        city: "",
        city_id: "",
        district: "",
        district_id: "",
      };
    },
    encode(str) {
      return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
          return String.fromCharCode('0x' + p1);
        }));
    },
    getBoxList() {

      axios.get(`${window.location.origin}/api/v1/box/index?box_id=${this.box_id}`).then(res => {
        if (res.data.code == 0) {

          this.price_view = res.data.data.box.price
          this.detailsData = res.data.data.box

        }
      })
      axios.post(`${window.location.origin}/api/v1/channel/getPayChannel`, {
        equipment_client: this.phoneOs() == 'IOS' ? 3 : 2,
        pay_client: 4, //3: h5  2:xiaochengxu  1:app
        channel_no: this.channel_no
      }).then(res => {
        this.payIcon = res.data.data.pay_way
      })
    },
    getPayment() {
      if (!this.phone) {
        return showToast('请输入手机号')
      }
      const rep = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (!rep.test(this.phone)) {
        return showToast('请输入正确的手机号')
      }
      if (!this.username) {
        showToast("请输入您的姓名");
        return false;
      }
      if (!this.tihuoObj.province) {
        showToast("请选择省市区");
        return false;
      }
      if (!this.addrss_detail) {
        showToast("请输入详细地址");
        return false;
      }
      let that = this;
      if (!this.subTrue) {
        return
      }
      if (this.subTrue) {
        this.subTrue = false
      }
      this.showLoad = true
      const {
        pay_price,
        phone,
        pay_type,
        goods_id,
        pay_mode,
        adv_m_id,
        adv_client_id,
        channel_id,
        callback_url,
        channel_no,
        coupon_id,
        payIcon,
        platfrom,
        openid
      } = this;
      axios.post(`${window.location.origin}/api/v1/channel/getPayChannel`, {
        equipment_client: this.phoneOs() == 'IOS' ? 3 : 2,
        pay_client: 4, //3: h5  2:xiaochengxu  1:app
        channel_no: channel_no
      }).then(res => {
        if (res.data.data.pay_way != 4) {
          axios
            .post(`${window.location.origin}/api/v1/tui/orderDeliverSubmit`, {
              pay_price,
              phone,
              pay_type: res.data.data.pay_way,
              channel_id: res.data.data.channel_id,
              coupon_id,
              goods_id,
              callback_url:
                callback_url +
                `/?channel_no=${channel_no}&coupon_id=1&phone=${that.encode(
                  phone
                )}#/`,
              pay_mode,
              goods_name: "手机",
              return_url: window.location.origin + "/#/successDetail",
              adv_m_id,
              adv_client_id,
              ...this.tihuoObj,
              username: this.username,
              detail: this.addrss_detail
            })
            .then((res) => {
              let order_url = escape(res.data.data.return_url);
              that.order_url_c = escape(res.data.data.return_url);
              if (res.data.code == 0) {
                this.subTrue = true
                if (platfrom == "1" || platfrom == "2" || platfrom == "6" || platfrom == "11" || platfrom == "13" || platfrom == '14' || platfrom == '17' || platfrom == "18" || platfrom == "21") {
                  //证明用户点击过后才进入后台
                  that.buttonChange = true;
                  // that.returnUrl=res.data.data.return_url
                  let gourl = ''
                  if (window.location.href.indexOf('return_url') > -1) {
                    gourl = window.location.href.split('return_url')[0] + '#/' + window.location.href.split('return_url')[1].split('#/')[1]
                  }
                  let newurl = that.updateQueryStringParameter(
                    gourl ? gourl : window.location.href,
                    "return_url",
                    order_url
                  );
                  window.history.replaceState(
                    {
                      path: newurl,
                    },
                    "",
                    newurl
                  );
                }
                // that.$router.push({path:'/payPage',query: {url: res.data.data.pay_data}})
                if (platfrom == "13") {
                  let url = res.data.data.pay_data.split("//");
                  // url.splice(4,0,'convertPayway')
                  let src = url[1].split("/");
                  src.splice(3, 0, "convertPayway");
                  let sure = src.join("/");
                  url = url[0] + "//" + sure;
                  axios
                    .post(url, {
                      wayCode: that.payIcon == 2 ? "ALI_WAP" : "WX_H5",
                      payDataType: "",
                    })
                    .then((bizData) => {
                      if (bizData.data.data.payDataType == "payurl") {
                        if (that.payIcon == 2) {
                          window.location.href = bizData.data.data.payData;
                        } else {
                          var m =
                            bizData.data.data.payData.match(/wxaurl\.cn\/(\w+)/);
                          if (m && m[1]) {
                            let mockUrlScheme = `weixin://dl/business/?t=${m[1]}`;
                            window.location.href = mockUrlScheme;
                          }
                        }
                      }
                    });
                } else {
                  window.location.href = res.data.data.pay_data;
                }
              } else {
                console.log(res);
                showToast(res.data.msg);
                this.subTrue = true
                //   setTimeout(() => {
                //     this.back();
                //   }, 3000);
              }
            })
            .catch((err) => {
              console.log(err);
              showToast(err);
              this.subTrue = true
              setTimeout(() => {
                this.back();
              }, 3000);
            });
        } else {

          axios
            .post(`/api/v1/tui/sub/mini/pre/submit`, {
              pay_price,
              phone,
              pay_type: res.data.data.pay_way,
              channel_id: res.data.data.channel_id,
              coupon_id,
              goods_id,
              callback_url:
                callback_url +
                `/?channel_no=${channel_no}&coupon_id=1&phone=${that.encode(
                  phone
                )}#/`,
              pay_mode: 1,
              goods_name: "手机",
              return_url: window.location.origin + "/#/successDetail",
              adv_m_id,
              adv_client_id,
              ...this.tihuoObj,
              username: this.username,
              detail: this.addrss_detail
            })
            .then((order) => {
              this.showLoad = false
              let returnUrl = `${order.data.data.return_url}?order_id=${order.data.data.order_id}&user_id=${order.data.data.user_id}&b=${this.encode(order.data.data.callback_url)}`
              let order_url = escape(returnUrl);
              that.order_url_c = escape(returnUrl);
              if (platfrom) {
                //证明用户点击过后才进入后台
                that.buttonChange = true;
                // that.returnUrl=res.data.data.return_url
                let gourl = "";
                if (window.location.href.indexOf("return_url") > -1) {
                  gourl =
                    window.location.href.split("return_url")[0] +
                    "#/" +
                    window.location.href.split("return_url")[1].split("#/")[1];
                }
                let newurl = that.updateQueryStringParameter(
                  gourl ? gourl : window.location.href,
                  "return_url",
                  order_url
                );
                window.history.replaceState(
                  {
                    path: newurl,
                  },
                  "",
                  newurl
                );
              }
              if (platfrom == '21') {
                var m = res.data.data.pay_data.match(/wxaurl\.cn\/(\w+)/)
                if (m && m[1]) {
                  let mockUrlScheme = `weixin://dl/business/?t=${m[1]}`
                  window.location.href = mockUrlScheme
                }
              } else {
                window.location.href = res.data.data.pay_data

              }

              // axios.get(`https://api.weixin.qq.com/cgi-bin/token?grant_type=grant_type&appid=${payDataWx.wechat_appid}&secret=${payDataWx.wechat_secret}`).then(res=>{
              //   console.log(res)
              // })
              // window.location.href=`https://duanju-5gfcawng77549bac-1320518902.tcloudbaseapp.com/index.html?sign=649e6061df9b0ecbda5985ba4efe0d0b&t=1693390891&order=${res.data.data.order_id}&appid=${payDataWx.wechat_appid}&envid=${payDataWx.wechat_env_id}`
            })
            .catch((err) => {
              console.log(err);
              showToast(err);
            });
        }

      })

    }
  }
}
</script>

<style lang="less" scoped>
input {
  zoom: none !important;
}

.bodyBox {
  font-size: .35rem;
}

.title {
  font-size: .5rem;
  font-weight: 600;
  text-align: center;
  padding: 10px 0;
}

.flex_box {
  display: flex;
  align-items: center;

}

.nameFlex {
  flex: 1;
  display: flex;
  align-items: center;
}

.color_red {
  margin-top: .2rem;
  color: red;
  font-size: .4rem;
}

.textOne {
  width: 4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.button {
  padding: 0 0.6rem;
  margin-top: 1.29rem;

  .btn {
    width: 100%;
    height: 1.17rem;
    background: linear-gradient(90deg, #ff3232 0%, #fa4c4c 100%);
    border-radius: 0.89rem;
    font-size: 0.43rem;
    font-weight: 400;
    color: #fefefe;
    text-align: center;
    line-height: 1.17rem;
  }
}

.radio {
  margin-top: 0.2rem;
  font-size: 0.34rem;
}
</style>